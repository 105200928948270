import React from 'react';
import { URL } from '../config';

const ErrorCard = ({ detail, title }) => (
  <div className='d-flex justify-content-center py-5'>
    <div className='px-2 py-4 w-100' style={{ maxWidth: '520px' }}>
      <div className='container px-0'>
        <div className='d-flex justify-content-center align-items-center'>
          <div className='col-8'>
            <h4 className='mb-3 font-weight-bold'>Something's wrong</h4>
            <h6 className='fs18 text-muted font-weight-normal'>{detail}</h6>
            <h5 className='text-primary-blue'>{title}</h5>
          </div>
          <img
            className='p-3 w300'
            src={`${URL}error-loading.svg`}
            alt='error-loading'
          />
        </div>
      </div>
    </div>
  </div>
);
export default ErrorCard;
