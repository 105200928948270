import React from 'react';
import Utils from '../../utilities';

const ThemeContext = React.createContext();

export const ThemeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = React.useState(false);
  const [siderCollapsed, setSiderCollapsed] = React.useState(false);

  const toggleDarkMode = () => {
    setIsDarkMode((prevDarkMode) => !prevDarkMode);
    Utils.setStoredValue('isDarkMode', !isDarkMode);
  };

  const toggleSiderCollapsed = () => {
    setSiderCollapsed((prevState) => !prevState);
    Utils.setStoredValue('isCollapsed', !siderCollapsed);
  };

  React.useEffect(() => {
    let mode = Utils.getStoredValue('isDarkMode');
    if (mode) setIsDarkMode(mode);
    let sider = Utils.getStoredValue('isCollapsed');
    if (sider) setSiderCollapsed(sider);
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        isDarkMode,
        toggleDarkMode,
        siderCollapsed,
        toggleSiderCollapsed
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeContext };
