import ApiRequest from './ApiService';
import { API_ROOT } from '../config';

class cohortPreviewExportService {
  // Connections List
  getCohortPreviewList(data) {
    return ApiRequest.post(`${API_ROOT}cohortPreview`, data);
  }

  // Cohort Export Request Save
  saveCohortExportRequest(data) {
    return ApiRequest.post(`${API_ROOT}cohortExportRequest`, data);
  }

  // get global config
  getCohortGlobalConfig() {
    return ApiRequest.get(`${API_ROOT}globalConfig/GLOBAL`);
  }

  // update global config
  updateCohortGlobalConfig(data) {
    return ApiRequest.put(`${API_ROOT}globalConfig/GLOBAL`, data);
  }

  // get cohort export request data
  getCohortExportRequestData(cohortId, kpiTreeId) {
    return ApiRequest.get(
      `${API_ROOT}cohortRequestOptions/${kpiTreeId}/${cohortId}`
    );
  }

  // get request log for admin
  getCohortExportRequestLogs(pageSize, pageNum, data) {
    return ApiRequest.post(
      `${API_ROOT}cohortExportRequest/admin/${pageSize}/${pageNum}`,
      data
    );
  }

  // get request log for user
  getCohortExportRequestLogsForUser(pageSize, pageNum, data) {
    return ApiRequest.post(
      `${API_ROOT}cohortExportRequest/user/${pageSize}/${pageNum}`,
      data
    );
  }

  // update the request
  updateAdminCohortExportRequest(data) {
    return ApiRequest.put(`${API_ROOT}cohortExportRequest/admin`, data);
  }

  // update user the request
  updateUserCohortExportRequest(data) {
    return ApiRequest.put(`${API_ROOT}cohortExportRequest/user`, data);
  }
}

export default new cohortPreviewExportService();
