import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import utilities from '../utilities';
import { IDLE_TIMER } from '../config';

export default function IdleTimer({
  showSessionTimeoutModal
}) {
  const [state, setState] = useState('Active');

  const onIdle = () => {
    setState('Idle');
    console.log(new Date());
    showSessionTimeoutModal();
  };

  const onActiveAction = (state) => {
    if (!utilities.getStoredValue('isExpired')) setState(state);
  };

  const onActive = () => {
    onActiveAction('Active');
  };

  const onAction = () => {
    onActiveAction('Action');
  };

  useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 60 * 1000 * (IDLE_TIMER ? IDLE_TIMER : 30),
    throttle: 500
  });
}
