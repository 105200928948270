import utilities from './utilities';
import { refreshToken } from './services/authService';
import { REFRESHTOKENTIMER } from './config';

const refreshInterval =
  1000 * 60 * (REFRESHTOKENTIMER ? REFRESHTOKENTIMER : 25); // Interval for refreshing token (30 minutes)
let intervalId; // Start refreshing token at regular intervals

const updateToken = (newToken) => {
  let updatedResp = { ...utilities.getStoredValue('resp') };
  if (newToken) updatedResp.response.token = newToken;
  utilities.setStoredValue('resp', updatedResp);
};

// logic for refreshing token
function refreshTokenUpdate() {
  console.log('Token Refresh Call ...');
  refreshToken()
    .then((response) => {
      response = utilities.manageSuccesResponse(response);
      updateToken(response.data.response.accessToken);
    })
    .catch((error) => {
      error = utilities.manageErrorResponse(error);
      console.error(error);
    });
}

const clearIntervelCall = () => {
  console.log('Token Refresh Stop Call ... ');
  clearInterval(intervalId);
};

const startIntervelCall = () => {
  console.log('Token Refresh Start Call ... ');
  intervalId = setInterval(refreshTokenUpdate, refreshInterval);
};

export { clearIntervelCall, startIntervelCall };
