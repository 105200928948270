import React from 'react';
import { Button } from '@mui/material';
import { URL } from '../config';

const CognitoCard = ({ loginData, resetLoggedIn }) => {
  return (
    <div className='ant-modal-root cognito-card d-none'>
      <div className='ant-modal-mask'></div>
      <div className='ant-modal-wrap' style={{ zIndex: 99999 }}>
        <div role='document' className='ant-modal row justify-content-center'>
          <div
            className='ant-modal-content px-2 py-4 w-100 text-center justify-content-center rounded'
            style={{ maxWidth: '520px' }}
          >
            <div className='container px-0'>
              <div className='row justify-content-center'>
                <div className='col-8 text-center'>
                  <img
                    className='p-3'
                    src={`${URL}refresh-session.svg`}
                    alt='refresh-session'
                  />
                  <h4 className='text-primary-blue mb-2'>
                    Your session has expired
                  </h4>
                  <p className='font-weight-normal fs14 mb-3'>
                    If you want to continue session as{' '}
                    <span className='text-primary-blue text-capitalize'>
                      {loginData ? loginData['name'] : 'user'}
                    </span>
                    , please re-login with ADFS.
                  </p>
                  <Button
                    data-testid='logout-button'
                    size='small'
                    variant='outlined'
                    className={`outline-none text-primary-blue px-3 py-1 mr-3`}
                    onClick={() => resetLoggedIn()}
                  >
                    Take me to Login
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CognitoCard;
