import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Popover } from 'antd';
import { Button, Card, Divider, Switch } from '@mui/material';

import { CONTEXT, URL } from '../config';
import { useTheme, useConfirmDialog } from '../hooks';
import Utils from '../utilities';
import PasswordModal from './passwordModal';

const Header = (props) => {
  const { name, mail, position, userPic } = props.loginData;
  const { isDarkMode, toggleDarkMode } = useTheme();
  const [popoverVisible, setPopoverVisible] = useState(false);

  const handlePopoverVisibleChange = (visible) => {
    setPopoverVisible(visible);
  };

  return (
    <div className='container-fluid position-fixed header-area'>
      <div className='row align-items-center'>
        <div className='col-6'>
          <Link to={CONTEXT}>
            <img
              className='ml-1'
              style={{ width: '13rem' }}
              alt='Incedo Lighthouse Logo'
              src={
                isDarkMode
                  ? `${URL}lighthouse-logo-white.png`
                  : `${URL}lighthouse-logo-black.png`
              }
            />
          </Link>
        </div>
        <div className='col-6 d-flex'>
          <div className='d-flex align-items-center ml-auto'>
            <span className='text-capitalize fs14 mr-3'>
              Hi, {name && name.split(' ')[0]}
            </span>
            <Popover
              placement='bottomRight'
              trigger='click'
              zIndex='1001'
              open={popoverVisible}
              onOpenChange={handlePopoverVisibleChange}
              content={
                <Card>
                  <Logout
                    authUser={name}
                    userMail={mail}
                    userPosition={position}
                    userPic={userPic}
                    logout={props.resetLoggedIn}
                    closePopover={() => setPopoverVisible(false)} // Close popover when password modal opens
                    toggleDarkMode={toggleDarkMode}
                    isDarkMode={isDarkMode}
                  />
                </Card>
              }
            >
              {userPic ? (
                <img
                  src={`data:image/jpeg;base64,${userPic}`}
                  alt='user'
                  style={{
                    borderRadius: '2rem',
                    height: '2rem',
                    verticalAlign: 'sub'
                  }}
                />
              ) : (
                <div className='d-flex justify-content-center align-items-center rounded-circle cursor-pointer mr-2 profile-icon'>
                  <span className='text-uppercase text-white'>
                    {name &&
                      name
                        .split(' ')
                        .map((n) => n[0])
                        .join('')}
                  </span>
                </div>
              )}
            </Popover>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Logout = (props) => {
  const hasRights = Utils.getUserRights();
  const redirectURLRef = useRef();
  const [openPasswordModal, setOpenPasswordModal] = useState(false);

  const closePasswordModalRequest = () => {
    setOpenPasswordModal(false);
  };

  const handlePasswordChange = async () => {
    props.closePopover();
    setOpenPasswordModal(true);
  };

  return (
    <div
      className='container header-area py-3'
      style={{ minWidth: `230px` }}
      ref={redirectURLRef}
    >
      <div className='d-flex align-items-center'>
        {props.userPic ? (
          <img
            src={`data:image/jpeg;base64,${props.userPic}`}
            style={{
              borderRadius: '2rem',
              height: '2rem',
              verticalAlign: 'sub'
            }}
            alt='user'
          />
        ) : (
          <div className='d-flex justify-content-center align-items-center rounded-circle cursor-pointer mr-2 profile-icon'>
            <span className='text-uppercase text-white'>
              {props.authUser &&
                props.authUser
                  .split(' ')
                  .map((n) => n[0])
                  .join('')}
            </span>
          </div>
        )}
        <h6 className='flex-gow-1 fs14 mb-0 text-capitalize text-primary-blue'>
          {props.authUser}
          <span className='d-block text-muted fs12'>{props.userPosition}</span>
        </h6>
      </div>
      <Divider className='my-3' />
      <div>
        <h6>
          <span className='d-block text-primary-blue fs12 mb-1'>Email</span>
          <span className='d-block text-muted fs12'>{props.userMail}</span>
        </h6>
        <h6>
          <span className='d-block text-primary-blue fs12 mb-1'>Role Set</span>
          <span className='d-block text-muted fs12'>
            {hasRights.join(', ')}
          </span>
        </h6>
      </div>
      {/* For Dark theme implementation */}
      {/* <div className="d-flex align-items-center mt-3">
        <Switch
          className="mr-2"
          checked={props.isDarkMode}
          onChange={() => props.toggleDarkMode()}
          inputProps={{
            "aria-label": "controlled",
            "data-testid": `toggle_theme`,
          }}
        />
        <span className={`small bold500 mb-0 ml-2 text-dark`}>{props.isDarkMode ? `DARK` : `LIGHT`} MODE</span>
      </div> */}
      <Divider className='my-3' />
      <div>
        <Button
          size='small'
          variant='text'
          className='w-100 mb-2'
          onClick={() => handlePasswordChange()}
        >
          Change Password
        </Button>
      </div>
      <div>
        <Button
          size='small'
          variant='outlined'
          className='w-100 bg-primary-blue text-white'
          onClick={() => props.logout()}
        >
          Logout
        </Button>
      </div>
      {openPasswordModal && (
        <PasswordModal
          open={openPasswordModal}
          onClose={closePasswordModalRequest}
        />
      )}
    </div>
  );
};

export default Header;
