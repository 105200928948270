import ApiRequest from './ApiService';
import { API_ROOT } from '../config';

class MenuService {
  getContent(domain) {
    return ApiRequest.get(API_ROOT + `mainMenu/${domain}`);
  }

  setMenuTreeData(data) {
    return ApiRequest.post(API_ROOT + `mainMenu`, data);
  }

  appMenu() {
    return ApiRequest.get(API_ROOT + `menu`);
  }

  setAppMenu(data) {
    return ApiRequest.put(API_ROOT + `menu`, data);
  }
}

export default new MenuService();
