import ApiRequest from './ApiService';
import { API_ROOT } from '../config';

export function getBrainSparkUserComm(data) {
  return ApiRequest.post(`${API_ROOT}rasa/`, data);
}

//Insights
export function getInsightsForKpiTree(kpiTreeId) {
  return ApiRequest.get(
    `${API_ROOT}control-center/insights?kpiTreeId=${kpiTreeId}`
  );
}

export function updateInsight(insightId, payload) {
  return ApiRequest.put(
    `${API_ROOT}control-center/insights/${insightId}`,
    payload
  );
}
export function deleteInsight(insightId) {
  return ApiRequest.delete(`${API_ROOT}control-center/insights/${insightId}`);
}

export function likeInsight(insightId, state) {
  return ApiRequest.patch(
    `${API_ROOT}control-center/insights/${insightId}/like?state=${state}`
  );
}

export function bookmarkInsight(insightId, state) {
  return ApiRequest.patch(
    `${API_ROOT}control-center/insights/${insightId}/bookmark?state=${state}`
  );
}

// CRUD for Comments for Insights
export function saveCommentForInsight(insightId, payload) {
  console.log(insightId, payload);
  return ApiRequest.post(
    `${API_ROOT}control-center/insights/${insightId}/comments`,
    payload
  );
}

export function getCommentsForInsight(insightId) {
  return ApiRequest.get(
    `${API_ROOT}control-center/insights/${insightId}/comments`
  );
}

export function updateCommentByCommentId(commentId, payload) {
  return ApiRequest.put(
    `${API_ROOT}control-center/comments/${commentId}`,
    payload
  );
}

export function deleteCommentByCommentId(commentId) {
  return ApiRequest.delete(`${API_ROOT}control-center/comments/${commentId}`);
}

//File Upload
export function uploadCsvFile(data, headers) {
  return ApiRequest.post(`${API_ROOT}uploadFile`, data, {
    headers: {
      'Content-Type': 'multipart/form-data' // Necessary for file uploads
    }
  });
}
