import React from 'react';
import { useParams } from 'react-router-dom';
import Utils from '../../utilities';
import kpiService from '../../services/kpiService';
import utilities from '../../utilities';

const KPIContext = React.createContext();

export const KPIProvider = ({ children }) => {
  const { treeId } = useParams();
  const kpiTreeId = treeId;

  const [isLoading, setLoading] = React.useState(false);
  const [isError, setError] = React.useState('');
  const [sampleKPITree, setSampleKPITree] = React.useState([]);
  const [treeData, setTreeData] = React.useState([]);
  const [kpiData, setKpiData] = React.useState([]);
  const [treeFilter, setTreeFilter] = React.useState([]);
  const [treeFilterResponse, setTreeFilterResponse] = React.useState(null);
  const [toggleTreelist, setToggleTreelist] = React.useState(false);
  const { isJsonString } = Utils;

  const getFilteredAnalysis = (initialLoad, treeFilterArg) => {
    setLoading(true);
    let selectedFilter = {};
    let allFilters = [];

    if (initialLoad && treeFilterArg === '') {
      selectedFilter = '';
    } else {
      allFilters = initialLoad ? treeFilterArg : treeFilter;
      allFilters.forEach(
        (filter) => (selectedFilter[filter.name] = filter.selected)
      );
      setTreeFilter(allFilters);
    }

    const postData = {
      kpiTreeId: kpiTreeId,
      filter: JSON.stringify(selectedFilter)
    };

    kpiService
      .getFilteredAnalysis(postData)
      .then((response) => {
        response = utilities.manageSuccesResponse(response);
        if (response.data.code === 200) {
          setSampleKPITree(response.data.response.kpiTree);
          setTreeData({
            analysisId: kpiTreeId,
            analysisName: response.data.response.kpiTree[0].treeName,
            enableCohortOps: response.data.response.enableCohortOps,
            analysisGoal: response.data.response.kpiTreeGoal,
            colorThreshold: response.data.response.colorThreshold,
            selectedFilter: selectedFilter,
            polarity: JSON.parse(response.data.response.polarity),
            unit: JSON.parse(response.data.response.unit),
            decimalPrecisionValues: JSON.parse(response.data.response.decimalPrecision),
            drivers: isJsonString(response.data.response.populateDrivers),
            kpiTemplateName: response.data.response.templateName,
            groupName: response.data.response?.groupName ?? '',
            chosenFilter: isJsonString(response.data.response.chosenFilter),
            cohortName: isJsonString(response.data.response.cohortName),
            documentConfig: response.data.response.documentConfig,
            treeDate: {
              createdDate: response.data.response.createdDate,
              modifiedDate: response.data.response.modifiedDate,
              refreshDate: response.data.response?.refreshDate,
              publishedDate: response.data.response?.publishedDate
            }
          });
        } else {
          setError(response.data?.message);
          console.log(response.data?.message);
        }
      })
      .catch((error) => {
        error = utilities.manageErrorResponse(error);
        setError(error?.message);
      })
      .finally(() => setLoading(false));
  };

  const getKPITreeData = () => {
    setLoading(true);
    if (kpiTreeId !== '') {
      kpiService
        .getTreeNodes(kpiTreeId)
        .then((response) => {
          response = utilities.manageSuccesResponse(response);
          if (response.data.code === 200) {
            let nodeId = JSON.parse(response.data.response.rawTree)[0].nodeId;

            setSampleKPITree([
              { ...JSON.parse(response.data.response.sampleTree)[0], nodeId }
            ]);
            setKpiData([...JSON.parse(response.data.response.rawTree)]);
            setTreeData({
              analysisId: response.data.response.kpiTreeId,
              analysisName: response.data.response.kpiTreeName,
              analysisGoal: response.data.response.kpiTreeGoal,
              colorThreshold: response.data.response.colorThreshold,
              drivers: isJsonString(response.data.response.populateDrivers),
              kpiTemplateName: response.data.response.templateName,
              groupName: response.data.response?.groupName ?? '',
              kpiTreeParameter: isJsonString(
                response.data.response.kpiTreeParameter
              ),
              cohorts: isJsonString(response.data.response.cohortName),
              kpiTreeDomain: response.data.response.kpiTreeDomain,
              kpiTreeStatus: response.data.response.kpiTreeStatus,
              enableCohortOps: response.data.response.enableCohortOps,
              treeFilter: null,
              polarity: null,
              unit: null,
              treeDate: {
                createdDate: response.data.response.createdDate,
                modifiedDate: response.data.response.modifiedDate,
                refreshDate: response.data.response?.refreshDate,
                publishedDate: response.data.response?.publishedDate
              },
              connectionName: response.data.response.connectionName,
              chosenFilter: isJsonString(response.data.response.chosenFilter),
              documentConfig: response.data.response.documentConfig,
              globalPrimaryQuery: response.data.response.globalPrimaryQuery
            });
          } else {
            setError(response.data?.message);
            console.error(response.data?.message);
          }
        })
        .catch((error) => {
          error = utilities.manageErrorResponse(error);
          setError(error?.message);
        })
        .finally(() => setLoading(false));
    }
  };

  const getFilterList = (data) => {
    let arrTreeFilter = [];
    if (data !== '') {
      let ar = [];
      data.forEach((item) => {
        Object.entries(item).forEach((el) => {
          ar = [...ar, el[0]];
        });
      });

      let obj = {};
      [...new Set(ar)].forEach((rec) => {
        data.forEach((item) => {
          if (item.hasOwnProperty(rec)) {
            if (obj[rec]) {
              obj = { ...obj, [rec]: [...obj[rec], item[rec]] };
            } else {
              obj = { ...obj, [rec]: [item[rec]] };
            }
          }
        });
      });

      const objectArray = Object.entries(obj);
      objectArray.forEach(([key, value]) => {
        arrTreeFilter.push({ name: key, value: value, selected: value[0] });
      });
    } else {
      arrTreeFilter = '';
    }
    setTreeFilter(arrTreeFilter);
    getFilteredAnalysis(true, arrTreeFilter);
  };

  const getKPITreeFilter = () => {
    setLoading(true);
    if (kpiTreeId !== '') {
      kpiService
        .getKPITreeFilter(kpiTreeId)
        .then((response) => {
          response = utilities.manageSuccesResponse(response);
          const parsedFilters = response.data.response.map((item) =>
            JSON.parse(item)
          );
          setTreeFilterResponse(parsedFilters);
          if (parsedFilters.length) {
            getFilterList([...parsedFilters]);
          }
        })
        .catch((error) => {
          error = utilities.manageErrorResponse(error);
          console.error(error.message);
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <KPIContext.Provider
      value={{
        isLoading,
        setLoading,
        isError,
        kpiTreeId,
        sampleKPITree,
        treeData,
        kpiData,
        treeFilter,
        treeFilterResponse,
        setSampleKPITree,
        setTreeData,
        setKpiData,
        getFilteredAnalysis,
        setTreeFilterResponse,
        getKPITreeData,
        getKPITreeFilter,
        toggleTreelist,
        setToggleTreelist
      }}
    >
      {children}
    </KPIContext.Provider>
  );
};

export { KPIContext };
