import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button
} from '@mui/material';

const ConfirmDialogContext = React.createContext();

export const ConfirmDialogProvider = ({ children }) => {
  const [confirm, setConfirm] = React.useState({
    prompt: '',
    isOpen: false,
    proceed: null,
    cancel: null,
    btnTextYes: '',
    btnTextNo: ''
  });

  return (
    <ConfirmDialogContext.Provider value={[confirm, setConfirm]}>
      {children}

      <Dialog open={confirm.isOpen}>
        <DialogContent>
          <DialogContentText
            id='alert-dialog-description'
            className='text-dark'
          >
            {confirm.prompt}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            data-testid='cancel-btn'
            onClick={confirm.cancel}
            color='primary'
          >
            {confirm.btnTextNo}
          </Button>
          <Button
            data-testid='ok-btn'
            onClick={confirm.proceed}
            color='primary'
            autoFocus
          >
            {confirm.btnTextYes}
          </Button>
        </DialogActions>
      </Dialog>
    </ConfirmDialogContext.Provider>
  );
};

export { ConfirmDialogContext };
