import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  Drawer,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Toolbar,
  Divider,
  Collapse
} from '@mui/material';
import { useMenu, useTheme } from '../hooks';
import Loader from '../utilities/Loader';
import Utils from '../utilities';
import { CONTEXT } from '../config';

const Sidebar = () => {
  const { routes } = useMenu();
  const allowedRoutes = routes
    .filter((item) => {
      const ispermission = Utils.getStoredValue('resp')?.response.memberOf.some(
        (role) => {
          return item?.permission
            ? item.permission.length > 0
              ? item.permission.includes(role)
              : true
            : true;
        }
      );
      if (ispermission) {
        return true;
      } else {
        return false;
      }
    })
    .map((item) => {
      const menuItem = item;
      if (menuItem.children) {
        menuItem.children = menuItem.children.filter((child) => {
          if (child.permission) {
            return Utils.getStoredValue('resp')?.response.memberOf.some(
              (item) => {
                return child?.permission
                  ? child.permission.length > 0
                    ? child.permission.includes(item)
                    : true
                  : true;
              }
            );
          } else {
            return true;
          }
        });
      }
      return menuItem;
    });

  const { siderCollapsed, toggleSiderCollapsed } = useTheme();
  const [expand, setExpand] = React.useState('');

  const handleDrawerOpen = () => {
    toggleSiderCollapsed();
    setExpand('');
  };

  const handleExpand = (idx) => {
    if (siderCollapsed) toggleSiderCollapsed();
    setExpand(idx);
  };

  return (
    <Drawer
      variant='permanent'
      className={`MuiDrawer-menu ${
        siderCollapsed ? 'MuiDrawer-menu-collapsed' : ''
      }`}
    >
      <Toolbar variant='dense' className='px-1'>
        <IconButton
          data-testid='menu'
          className='ml-1 mr-2'
          onClick={() => handleDrawerOpen()}
        >
          <Icon>{`menu`}</Icon>
        </IconButton>
        <ListItemText primary={`Menu`} />
        <IconButton
          data-testid='chevron_left'
          onClick={() => handleDrawerOpen()}
        >
          <Icon>{`chevron_left`}</Icon>
        </IconButton>
      </Toolbar>
      <Divider />
      <List
        className='py-0'
        style={{
          overflowY: 'auto',
          overflowX: 'hidden',
          height: 'calc(100% - 190px)'
        }}
      >
        {allowedRoutes.length ? (
          allowedRoutes
            .filter((elem) => !elem.hide && !elem.visibility)
            .map((item, idx) => (
              <MenuItem
                key={idx}
                idx={idx}
                item={item}
                expand={expand}
                handleExpand={handleExpand}
              />
            ))
        ) : (
          <ListItem>
            <Loader className='mx-auto' />
          </ListItem>
        )}
      </List>

      <List className='w-100 py-0 MuiList-helpGuide'>
        <Divider />
        <ListItemButton
          title={`Tutorial Videos`}
          component={Link}
          to={CONTEXT + `tutorial-videos`}
          className={`py-0`}
        >
          <ListItem disableGutters>
            <ListItemIcon>
              <Icon className='material-icons-outlined'>videocam</Icon>
            </ListItemIcon>
            <ListItemText primary={`Tutorial Videos`} />
          </ListItem>
        </ListItemButton>
        <Divider />
        <ListItemButton
          title={`Help Guide`}
          component={Link}
          to={CONTEXT + `help-guide`}
          className={`py-0`}
        >
          <ListItem disableGutters>
            <ListItemIcon>
              <Icon className='material-icons-outlined'>help_outline</Icon>
            </ListItemIcon>
            <ListItemText primary={`Help Guide`} />
          </ListItem>
        </ListItemButton>
      </List>
    </Drawer>
  );
};

const MenuItem = ({ idx, item, expand, handleExpand }) => {
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState('');
  const location = useLocation();
  const { pathname } = location;

  React.useEffect(() => {
    setSelected(pathname);
  }, [navigate]);

  return item.allowSubmenu ? (
    <>
      <ListItemButton
        title={item.title}
        onClick={() => handleExpand(idx === expand ? '' : idx)}
        className={`cursor-pointer pr-2 ${
          selected.includes(item.route) ? 'active' : ''
        }`}
      >
        <ListItemIcon>
          <Icon className='material-icons-outlined'>{item.icon}</Icon>
        </ListItemIcon>
        <ListItemText
          title={item.title}
          primary={item.title}
          primaryTypographyProps={{
            style: {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }
          }}
        />
        <Icon className='expand-icon'>
          {idx === expand ? 'expand_less' : 'expand_more'}
        </Icon>
      </ListItemButton>
      <Collapse in={idx === expand} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {item['children'].map((subItem, i) => { 
            return (
            <>
              <ListItemButton key={i} className='py-0 subMenuListItem'>
                <ListItem
                  className={`py-0 ${
                    selected.includes(subItem.route) && selected.includes(item.route) ? 'active' : ''
                  }`}
                  disableGutters
                  component={Link}
                  to={CONTEXT + item.route + '/' + subItem.route}
                >
                  <ListItemText
                    title={subItem.title}
                    primary={subItem.title}
                    primaryTypographyProps={{
                      style: {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }
                    }}
                  />
                </ListItem>
              </ListItemButton>
              {`children` in subItem &&
                subItem['children'].map((subSubItem, i) => (
                  <ListItemButton key={i} className='py-0 subSubMenuListItem'>
                    <ListItem
                      className={`py-0 ${
                        selected.includes(subSubItem.route) ? '' : ''
                      }`}
                      disableGutters
                      component={Link}
                      to={CONTEXT + item.route + '/' + subSubItem.route}
                    >
                      <ListItemText
                        title={subSubItem.title}
                        primary={'-- ' + subSubItem.title}
                        primaryTypographyProps={{
                          style: {
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          }
                        }}
                      />
                    </ListItem>
                  </ListItemButton>
                ))}
            </>
          )})}
        </List>
      </Collapse>
    </>
  ) : (
    <ListItemButton
      title={item.title}
      component={Link}
      to={CONTEXT + item.route}
      className={`py-0 ${selected.includes(item.route) ? 'active' : ''}`}
    >
      <ListItem disableGutters>
        <ListItemIcon>
          <Icon className='material-icons-outlined'>{item.icon}</Icon>
        </ListItemIcon>
        <ListItemText primary={item.title} />
      </ListItem>
    </ListItemButton>
  );
};
export default Sidebar;
