import React, { useState } from 'react';
import { Modal } from 'antd';
import {
  TextField,
  InputAdornment,
  Button,
  Icon,
  IconButton
} from '@mui/material';
import { useSnackBar } from '../hooks';
import utilities from '../utilities';
import validator from 'validator';
import { passwordUpdate } from '../services/authService';

const PasswordModal = ({ open, onClose }) => {
  const { snackBar } = useSnackBar();
  const [newPassword, setNewPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const showMsg = (messageStatus, message) => {
    snackBar({
      status: true,
      severity: messageStatus,
      message: message
    });
  };

  const updatePassword = () => {
    if (oldPassword === '') {
      showMsg('error', 'Old Password Required');
      return;
    }
    if (newPassword === '') {
      showMsg('error', 'New Password Required');
      return;
    }
    if (confirmPassword === '') {
      showMsg('error', 'Confirm Password Required');
      return;
    }
    if (newPassword !== confirmPassword) {
      showMsg('error', 'New Password and Confirm Password did not match');
      return;
    }

    if (
      !validator.isStrongPassword(newPassword, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1
      }) ||
      newPassword.includes(' ')
    ) {
      showMsg(
        'error',
        'The new password does not conform to the password policy.'
      );
      return;
    }

    passwordUpdate({
      confirmPassword: confirmPassword,
      newPassword: newPassword,
      oldPassword: oldPassword
    })
      .then((response) => {
        response = utilities.manageSuccesResponse(response);
        if (response.data.code === 200) {
          showMsg('success', 'Password updated successfully');
          onClose(false);
        } else {
          if (response?.data?.message) {
            showMsg('error', response?.data?.message);
          }
        }
      })
      .catch((error) => {
        error = utilities.manageErrorResponse(error);
        showMsg(
          'error',
          error.response.data?.message ?? 'something went wrong'
        );
      });
  };

  return (
    <Modal
      open={open}
      title='Change Password'
      onCancel={() => onClose(false)}
      footer={[
        <div className='d-flex justify-content-between'>
          <Button
            size='medium'
            variant='outlined'
            className='box-shadow-none'
            onClick={() => onClose(false)}
          >
            Cancel
          </Button>
          <Button
            color='primary'
            size='medium'
            variant='contained'
            className='box-shadow-none bg-primary-blue'
            onClick={() => updatePassword()}
          >
            Update
          </Button>
        </div>
      ]}
    >
      <div>
        <form className='needs-validation' noValidate>
          <label htmlFor='password-input' className='d-none'>
            Old Password
          </label>
          <TextField
            className='w-100 mb-4'
            required
            size='small'
            label='Old Password'
            name='old password'
            variant='outlined'
            type={showOldPassword ? 'text' : 'password'}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setOldPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    className='outline-none toggle-password-visibility'
                    onClick={() => setShowOldPassword(!showOldPassword)}
                    data-testid='visibility-btn'
                  >
                    {showOldPassword ? (
                      <Icon>visibility</Icon>
                    ) : (
                      <Icon>visibility_off</Icon>
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <label htmlFor='password-input' className='d-none'>
            New Password
          </label>
          <TextField
            className='w-100 mb-4'
            required
            size='small'
            label='New Password'
            name='new password'
            variant='outlined'
            type={showNewPassword ? 'text' : 'password'}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setNewPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    className='outline-none toggle-password-visibility'
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    data-testid='visibility-btn'
                  >
                    {showNewPassword ? (
                      <Icon>visibility</Icon>
                    ) : (
                      <Icon>visibility_off</Icon>
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <label htmlFor='password-input' className='d-none'>
            Confirm Password
          </label>
          <TextField
            className='w-100 mb-2'
            required
            size='small'
            label='Confirm Password'
            name='confirm password'
            variant='outlined'
            type={showConfirmPassword ? 'text' : 'password'}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setConfirmPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    className='outline-none toggle-password-visibility'
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    data-testid='visibility-btn'
                  >
                    {showConfirmPassword ? (
                      <Icon>visibility</Icon>
                    ) : (
                      <Icon>visibility_off</Icon>
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <div style={{ fontSize: '0.8rem', color: 'gray', marginTop: '4px' }}>
            Password must contain:
            <ul>
              <li>At least 8 characters</li>
              <li>At least one uppercase letter</li>
              <li>At least one lowercase letter</li>
              <li>At least one digit</li>
              <li>At least one special character (@#$%)</li>
              <li>No whitespace</li>
            </ul>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default PasswordModal;
