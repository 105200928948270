import axios from 'axios';
import { API_ROOT } from '../config';

export function getSSOUser() {
  return axios({ method: `GET`, url: `${API_ROOT}getSSOUser`, timeout: 5000 });
}

export function loginUser(data) {
  return axios({ method: `POST`, url: `${API_ROOT}loginApi`, data });
}

export function registerUser(data) {
  return axios({ method: `POST`, url: `${API_ROOT}signup`, data });
}

export function passwordChange() {
  return axios(`${API_ROOT}changePasswordRequest`);
}

export function refreshToken() {
  return axios(`${API_ROOT}refreshToken`);
}

export function passwordUpdate(data) {
  return axios({ method: `POST`, url: `${API_ROOT}changePasswordApi`, data });
}
