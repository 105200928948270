import React from 'react';
import { ConfirmDialogContext } from '../contexts/snackbar';

export const useConfirmDialog = () => {
  const [confirm, setConfirm] = React.useContext(ConfirmDialogContext);
  const [needsCleanup, setNeedsCleanup] = React.useState(false);

  const isConfirmed = (prompt, btnTextYes = 'Ok', btnTextNo = 'Cancel') => {
    const promise = new Promise((resolve, reject) => {
      setConfirm({
        prompt,
        isOpen: true,
        proceed: resolve,
        cancel: reject,
        btnTextYes,
        btnTextNo
      });
      setNeedsCleanup(true);
    });

    const reset = () => {
      setConfirm({
        prompt: '',
        proceed: null,
        cancel: null,
        isOpen: false,
        btnTextYes,
        btnTextNo
      });
      setNeedsCleanup(false);
    };

    return promise.then(
      () => {
        reset();
        return true;
      },
      () => {
        reset();
        return false;
      }
    );
  };

  // Call cancel in a cleanup func to avoid dangling confirm dialog
  React.useEffect(() => {
    return () => {
      if (confirm.cancel && needsCleanup) {
        confirm.cancel();
      }
    };
  }, [confirm, needsCleanup]);

  return {
    ...confirm,
    isConfirmed
  };
};
