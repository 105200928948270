import React from 'react';
import { Snackbar, Alert } from '@mui/material';

const SnackBarContext = React.createContext();

export const SnackBarProvider = ({ children }) => {
  const [ísAlert, setAlert] = React.useState({ status: false });
  const snackBar = (obj) => setAlert(obj);

  return (
    <SnackBarContext.Provider value={{ snackBar }}>
      {children}

      <Snackbar
        open={ísAlert.status}
        autoHideDuration={6000}
        transitionDuration={10}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: `horizontal` in ísAlert ? ísAlert.horizontal : 'right'
        }}
        onClose={() => setAlert({ status: false })}
      >
        <Alert
          onClose={() => setAlert({ status: false })}
          variant='filled'
          severity={ísAlert.severity}
          sx={{ width: '100%' }}
        >
          {ísAlert.message}
        </Alert>
      </Snackbar>
    </SnackBarContext.Provider>
  );
};

export { SnackBarContext };
