import React, { useState, useEffect, lazy, Suspense } from 'react';
import {
  Routes,
  Route,
  useParams,
  useLocation,
  useNavigate
} from 'react-router-dom';
import { CONTEXT } from '../config';
import { useMenu } from '../hooks';
import Loader from '../utilities/Loader';

const AppRoutes = () => {
  const { routes } = useMenu();

  return (
    <Routes>
      {routes.map((item, i) => (
        <Route
          key={i}
          exact={item.exact}
          path={CONTEXT + item.route}
          element={<PageRouter />}
        />
      ))}
    </Routes>
  );
};

const PageRouter = () => {
  const [page, setPage] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const { routes } = useMenu();
  const param = useParams();

  let paramList = routes.map((item) => item.params).filter((el) => el);
  paramList = [...new Set(paramList)];

  const selectedRoute = () => {
    let params = paramList.map((str) => param[str]).filter(Boolean);
    let path = params.length ? pathname.split('/' + params[0])[0] : pathname;

    let filtered = routes.filter((item) => CONTEXT + item.route === path);
    setPage(filtered.length ? filtered[0] : null);
  };

  useEffect(() => {
    selectedRoute();
  }, [pathname]);

  if (page == null) {
    return <div className='text-center m-5'>No page found!</div>;
  }

  const RenderComponent = lazy(() => import(`../modules/${page.path}.js`));

  return (
    <Suspense
      fallback={
        <div
          className={`d-flex flex-wrap align-items-center justify-content-center pt-3 ml-4 mt-5`}
        >
          <Loader />
        </div>
      }
    >
      <RenderComponent page={page} location={location} navigate={navigate} />
    </Suspense>
  );
};

export default AppRoutes;
