import React from 'react';
import Loader from '../assets/loader.svg';
import { Skeleton } from '@mui/material';
import './LHLoader.css';

const SpinnerLoader = (attr) => (
  <img
    src={Loader}
    alt='loader'
    width={attr.width || '30'}
    height={attr.height || '30'}
    className={attr.className || ''}
  />
);

export const ShimmerLoader = () => {
  return (
    <div className='d-flex flex-wrap justify-content-center'>
      {[...Array(15)].map((_e, _i) => (
        <Skeleton
          className='domain-card-skeleton bordered-8'
          variant='rect'
          width={344}
          height={312}
          animation='wave'
        />
      ))}
    </div>
  );
};

export const ShimmerLoaderBrainSpark = () => {
    return (
      <div className='d-flex my-3 flex-wrap justify-content-start'>
       <Skeleton
            className='mb-2'
            variant='rect'
            animation='wave'
            style={{ width: '100%', height: '12px', borderRadius: '5px' }}
          />
        <Skeleton
            variant='rect'
            animation='wave'
            style={{ width: '70%', height: '12px', borderRadius: '5px' }}
          />
      </div>
    );
}

export const ShimmerLoaderTree = () => {
  return (
    <div className='position-relative'>
      <div className='h-100'>
        <div>
          <div className='d-flex align-items-center justify-content-between border-bottom px-3'>
            <Skeleton animation='wave' variant='text' width={275} height={48} />
            <Skeleton animation='wave' variant='text' width={275} height={48} />
            <Skeleton animation='wave' variant='text' width={275} height={48} />
          </div>
          <div className='d-flex align-items-center justify-content-between border-bottom px-3'>
            <div className='d-flex'>
              <Skeleton
                animation='wave'
                variant='text'
                width={150}
                height={48}
              />
              <Skeleton
                className='ml-2'
                animation='wave'
                variant='text'
                width={150}
                height={48}
              />
            </div>
            <div className='d-flex'>
              <Skeleton
                animation='wave'
                variant='text'
                width={130}
                height={48}
              />
              <Skeleton
                className='ml-2'
                animation='wave'
                variant='text'
                width={130}
                height={48}
              />
            </div>
          </div>
          <div className='mt-3'>
            <div
              className='d-flex align-items-center justify-content-center'
              style={{ height: '170px' }}
            >
              <Skeleton
                animation='wave'
                variant='text'
                width={200}
                height={150}
              />
            </div>
            <div className='d-flex align-items-center justify-content-center'>
              <Skeleton
                className='mr-5'
                animation='wave'
                variant='text'
                width={200}
                height={150}
              />
              <Skeleton
                className='ml-5'
                animation='wave'
                variant='text'
                width={200}
                height={150}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ShimmerLoaderEditAnalysis = () => {
  return (
    <div
      className='container row mt-2 mx-0 px-5 mw-100 bordered-8'
      style={{ width: '100%', height: '600px' }}
    >
      <div className='row w-100 m-0'>
        <div className='p-0'>
          <Skeleton
            className='mb-2'
            variant='rect'
            animation='wave'
            style={{ width: '100%', height: '50px' }}
          />
        </div>
      </div>

      <div className='row shadow d-flex w-100 m-0' style={{ height: '600px' }}>
        <div className='col-2'>
          <Skeleton
            className='m-2 w-100'
            variant='rect'
            animation='wave'
            style={{ width: '100%', height: '50px' }}
          />
        </div>
        <div className='col-10'>
          <div className='row justify-content-center my-4'>
            <Skeleton
              className='my-2'
              variant='rect'
              animation='wave'
              height={112}
              style={{ width: '203px' }}
            />
          </div>
          <div className='row justify-content-center my-4'>
            <Skeleton
              className='my-2 mx-5'
              variant='rect'
              animation='wave'
              height={112}
              style={{ width: '203px' }}
            />
            <Skeleton
              className='my-2 mx-5'
              variant='rect'
              animation='wave'
              height={112}
              style={{ width: '203px' }}
            />
          </div>
          <div className='row justify-content-center my-4'>
            <Skeleton
              className='my-2 mx-1'
              variant='rect'
              animation='wave'
              height={112}
              style={{ width: '203px' }}
            />
            <Skeleton
              className='my-2 mx-1'
              variant='rect'
              animation='wave'
              height={112}
              style={{ width: '203px' }}
            />
            <Skeleton
              className='my-2 mx-1'
              variant='rect'
              animation='wave'
              height={112}
              style={{ width: '203px' }}
            />
            <Skeleton
              className='my-2 mx-1'
              variant='rect'
              animation='wave'
              height={112}
              style={{ width: '203px' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const ShimmerLoaderTable = () => {
  return (
    <div className='w-100'>
      <div className='w-100 border p-0' style={{ borderRadius: '8px' }}>
        <Skeleton
          className='mb-5'
          variant='rect'
          animation='wave'
          style={{ width: '100%', height: '50px', borderRadius: '8px 8px 0 0' }}
        />
        <div className='row justify-content-center'>
          {[...Array(8)].map((_e, i) => (
            <Skeleton
              key={i}
              className='bordered-8 px-5 mb-5'
              variant='rect'
              height={15}
              animation='wave'
              style={{ width: '95%' }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export const ShimmerLoaderActions = () => {
  return (
    <div
      data-testid='action-card-skeleton-wrapper'
      className='d-flex flex-wrap py-4 justify-content-center'
    >
      {[...Array(15)].map((_e, i) => (
        <Skeleton
          key={i}
          className='action-card-skeleton mx-auto mb-4'
          variant='rect'
          width={416}
          height={168}
          animation='wave'
        />
      ))}
    </div>
  );
};

export const LHLoader = () => {
  return (
    <React.Fragment>
      <div className='clip-rectangle'>
        <div className='lh-animate-container'>
          <div className='lh-animate-circle1'></div>
          <div className='lh-animate-incedo-i'></div>
          <div className='lh-animate-incedo-dot'></div>
          <div className='lh-animate-top'>
            <div className='lh-animate-top-p1'></div>
            <div className='lh-animate-top-p2'></div>
            <div className='lh-animate-top-p3'></div>
            <div className='lh-animate-top-p4'></div>
            <div className='lh-animate-top2'>
              <div className='lh-animate-top2-rect1'></div>
              <div className='lh-animate-top2-rect2'></div>
              <div className='lh-animate-top2-rect3'></div>
            </div>
            <div className='lh-animate-top3'>
              <div className='lh-animate-top3-rect1'></div>
              <div className='lh-animate-top3-rect2'></div>
              <div className='lh-animate-top3-rect3'></div>
              <div className='lh-animate-top3-rect4'></div>
              <div className='lh-animate-top3-rect5'></div>
            </div>
            <div className='lh-animate-top4'>
              <div className='lh-animate-top4-rect1'></div>
              <div className='lh-animate-top4-rect2'></div>
              <div className='lh-animate-top4-rect3'></div>
            </div>
            <div className='lh-animate-top5'>
              <div className='lh-animate-top5-rect1'></div>
              <div className='lh-animate-top5-rect2'></div>
              <div className='lh-animate-top5-rect3'></div>
              <div className='lh-animate-top5-rect4'></div>
              <div className='lh-animate-top5-rect5'></div>
            </div>
            <div className='lh-animate-top6'>
              <div className='lh-animate-top6-rect1'></div>
              <div className='lh-animate-top6-rect2'></div>
              <div className='lh-animate-top6-rect3'></div>
            </div>
            <div className='lh-animate-top7'>
              <div className='lh-animate-top7-rect1'></div>
              <div className='lh-animate-top7-rect2'></div>
              <div className='lh-animate-top7-rect3'></div>
            </div>
            <div className='lh-animate-top8'>
              <div className='lh-animate-top8-rect1'></div>
              <div className='lh-animate-top8-rect2'></div>
              <div className='lh-animate-top8-rect3'></div>
            </div>
            <div className='lh-animate-top9'>
              <div className='lh-animate-top9-rect1'></div>
              <div className='lh-animate-top9-rect2'></div>
              <div className='lh-animate-top9-rect3'></div>
            </div>
            <div className='lh-animate-undertop'>
              <div className='lh-animate-undertop-rect1'></div>
              <div className='lh-animate-undertop-rect2'></div>
              <div className='lh-animate-undertop-rect3'></div>
              <div className='lh-animate-undertop-rect4'></div>
              <div className='lh-animate-undertop-rect5'></div>
              <div className='lh-animate-undertop-rect6'></div>
            </div>
            <div className='lh-animate-mid1'>
              <div className='lh-animate-mid1-rect1'></div>
              <div className='lh-animate-mid1-rect2'></div>
              <div className='lh-animate-mid1-rect3'></div>
            </div>
            <div className='lh-animate-filler'></div>

            <div className='lh-animate-light-right'></div>
            <div className='lh-animate-light-left'></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SpinnerLoader;
