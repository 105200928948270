import React from 'react';
import { Button } from '@mui/material';
import { URL } from '../config';

const AccessCard = () => {
  const handleClose = () => {
    document.querySelector('.access-card').classList.add('d-none');
  };

  return (
    <div className='ant-modal-root access-card d-none'>
      <div className='ant-modal-mask'></div>
      <div className='ant-modal-wrap' style={{ zIndex: 99999 }}>
        <div role='document' className='ant-modal row justify-content-center'>
          <div
            className='ant-modal-content px-2 py-4 w-100 text-center justify-content-center rounded'
            style={{ maxWidth: '520px' }}
          >
            <div className='container px-0'>
              <div className='row justify-content-center'>
                <div className='col-8 text-center'>
                  <img
                    className='p-3'
                    src={`${URL}refresh-session.svg`}
                    alt='refresh-session'
                  />
                  <h4 className='text-primary-blue mb-2'>Access Denied!</h4>
                  <p className='font-weight-normal fs14 mb-3'>
                    You do not have sufficient permission to make this request.
                  </p>
                </div>
              </div>
              <Button
                size='small'
                className={`outline-none bg-primary-blue text-white px-3 py-1`}
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccessCard;
